import type { AriaToastProps } from '@react-aria/toast';
import { useToast } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { useButton } from 'react-aria';

import { BOOKING_WIDGET_NEXT_ENABLED } from '@/lib/features';

import { Icon } from '@/components/icon/icon';

interface ToastProps<T> extends AriaToastProps<T> {
  state: ToastState<T>;
}

export function Toast<T extends React.ReactNode>({
  state,
  ...props
}: ToastProps<T>) {
  const ref = useRef(null);
  const buttonRef = useRef(null);
  const toast = useToast(props, state, ref);

  const { buttonProps: closeButtonProps } = useButton(
    toast.closeButtonProps,
    buttonRef
  );

  const pathname = usePathname();

  const { close } = state;
  useEffect(() => {
    // Close toast on navigation
    return () => {
      // New booking widget will do redirects after adding to cart
      // @FIXME: Remove this after new booking widget is deployed
      if (BOOKING_WIDGET_NEXT_ENABLED) {
        return;
      }

      close(props.toast.key);
    };

    // 'close' function is omited on purpose. Find a better way?
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, props.toast.key]);

  return (
    <article
      {...toast.toastProps}
      className="-mb-24 overflow-hidden rounded-t-2xl bg-white pb-24 shadow-base md:mb-0 md:min-w-[400px] md:max-w-lg md:rounded-b-2xl md:pb-0"
      ref={ref}
    >
      <div className="flex items-center gap-2 bg-semantic-success-10 p-4 md:px-6">
        <Icon size={20} className="text-semantic-success-50" iconName="check" />
        <h1 className="flex-1 text-cap-base leading-none">
          Successfully added to your cart
        </h1>
        <button aria-label="Close" {...closeButtonProps}>
          <Icon size={20} iconName="x" />
        </button>
      </div>
      <div className="px-6 py-4">{props.toast.content}</div>
    </article>
  );
}
