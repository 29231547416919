import type { AriaToastRegionProps } from '@react-aria/toast';
import { useToastRegion } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { AnimatePresence, m } from 'framer-motion';
import { useRef } from 'react';

import { AnimateToast } from './animate-toast';
import { Toast } from './toast';

interface ToastRegionProps<T> extends AriaToastRegionProps {
  state: ToastState<T>;
}

export function ToastRegion<T extends React.ReactNode>({
  state,
  ...props
}: ToastRegionProps<T>) {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <div {...regionProps} ref={ref}>
      <AnimatePresence>
        {state.visibleToasts.length > 0 ? (
          <m.div
            key="backdrop"
            className="pointer-events-none fixed inset-0 z-30 bg-black md:hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.35 }}
            exit={{ opacity: 0 }}
          />
        ) : null}
        {state.visibleToasts.map((toast) => (
          <AnimateToast key={toast.key} onClose={() => state.close(toast.key)}>
            <Toast toast={toast} state={state} />
          </AnimateToast>
        ))}
      </AnimatePresence>
    </div>
  );
}
