'use client';

import { useToastQueue } from '@react-stately/toast';

import { toastQueue } from './state';
import { ToastRegion } from './toast-region';

export function GlobalToastRegion() {
  const state = useToastQueue(toastQueue);
  return <ToastRegion state={state} />;
}
